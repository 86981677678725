export const permissions = {
    Dashboard: {
        view: true,
    },
    Calendar: {
        view: true,
    },
    Event: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "events",
    },
    CheckIn: {
        view: true,
    },
    Customer: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "customers",
    },
    EventType: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "event-types"
    },
    Organizer: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "organizers",
    },
    RateManagement: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "rate-management",
    },
    Order: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "order",
    },
    Payment: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "payments",
    },
    StaffGroup: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "staff-groups",
    },
    Staff: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "staff",
    },
    Settings: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "user-settings",
    },
    Log: {
        view: true,
        management: true,
        url: "logs",
    },
    SeatTemplate: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "seat-template",
    },
    SeatType: { // TODO Rename to SeatTemplateType
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "seat-template-types",
    },
    ClosedDateManagement: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "closed-date-management",
    },
    Ticket: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "ticket-manager",
    },
    TicketType: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "ticket",
    },
    CustomerGroup: {
        view: true,
        create: false,
        update: false,
        delete: false,
        management: true,
        url: "customer-groups",
    }
};

export enum AccessPages {
    DASHBOARD = 'Dashboard',
    CALENDAR = 'Calendar',
    SEATTEMPLATE = 'SeatTemplate',
    SEATTYPE = 'SeatType',
    CLOSEDDATEMANAGEMENT = 'ClosedDateManagement',
    TICKET = 'Ticket',
    TICKETTYPE = 'TicketType',
    FAMILYTICKET = 'FamilyTicket',
    CUSTOMER = 'Customer',
    CUSTOMERGROUP = 'CustomerGroup',
    EVENT = 'Event',
    EVENTSERIES = 'EventSeries',
    EVENTTYPE = 'EventType',
    CHECKIN = 'CheckIn',
    ORDER = 'Order',
    ORGANIZER = 'Organizer',
    RATEMANAGEMENT = 'RateManagement',
    PAYMENT = 'Payment',
    STAFFGROUP = 'StaffGroup',
    STAFF = 'Staff',
    SETTINGS = 'Settings',
    LOG = 'Log',
}

export enum Actions {
    VIEW = 'view',
    CREATE = 'create',
    DELETE = 'delete',
    UPDATE = 'update',
    MANAGEMENT = 'management',
}