import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";


const routes: Routes = [
    { 
        path: ':uuid/thank-you', 
        loadChildren: () => 
            import('./thank-you-page/thank-you-page.module')
                .then(m => m.ThankYouPageModule) 
    },
    { 
        path: 'terms-conditions', 
        loadChildren: () => 
            import('./terms-and-conditions/terms-and-conditions.module')
                .then(m => m.TermsAndConditionsModule) 
    },
    { 
        path: 'register', 
        loadChildren: () => 
            import('./register/register.module')
                .then(m => m.RegisterModule) 
    },
    { 
        path: 'activate-account', 
        loadChildren: () => 
            import('./activate-account/activate-account.module')
                .then(m => m.ActivateAccountModule) 
    },
    { 
        path: 'forgot-password', 
        loadChildren: () => 
            import('./forgot-password/forgot-password.module')
                .then(m => m.ForgotPasswordModule) 
    },
    { 
        path: 'reset-password', 
        loadChildren: () => 
            import('./reset-password/reset-password.module')
                .then(m => m.ResetPasswordModule) 
    },
    { 
        path: 'log-in', 
        loadChildren: () => 
            import('./login/login.module')
                .then(m => m.LoginModule) 
    },
    // { 
    //     path: 'staff-log-in', 
    //     loadChildren: () => 
    //         import('./staff-login/staff-login.module')
    //             .then(m => m.StaffLoginModule) 
    // },
    {
        path: "user-events/:uuid",
        loadChildren: () => 
            import('./event-register/event-register.module')
                .then(m => m.EventRegisterModule) 
    },
    {
        path: "user-events/:uuid/event/:eventId",
        loadChildren: () => 
            import('./event-register/event-register.module')
                .then(m => m.EventRegisterModule) 
    },
    {
        path: "user-events/:uuid/event/:eventId/:info",
        loadChildren: () => 
            import('./event-register/event-register.module')
            .then(m => m.EventRegisterModule)
    },
    {
        path: "user-events/:uuid/:vippsOrderId",
        loadChildren: () => 
        import('./event-register/event-register.module')
            .then(m => m.EventRegisterModule) 
    },
    { 
        path: '', 
        loadChildren: () => 
            import('./layout/layout.module')
            .then(m => m.LayoutModule) 
    },
    { 
        path: 'test', 
        loadChildren: () => 
            import('./test/test-routing.module')
                .then(m => m.TestRoutingModule) 
    },
    { 
        path: '**', 
        loadChildren: () => 
            import('./not-found/not-found.module')
                .then(m => m.NotFoundModule) 
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, 
            {
                anchorScrolling: "enabled"
            }
        )
    ],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
