export const environment = {
	production: true,
	apiUrl: "https://testevent.susoft.com/api/v2",
	uploadsUrl:"/uploads",
	captchaKey: "6LeCD6YZAAAAAF4t_PwmhU3RlUdrA0TslsZdvi0n",
	orderTimeoutMinutes: 2880,
	googleClientId: "70590796606-e44l2sn3q1mf8dagqbuav8iqsglpgkv2.apps.googleusercontent.com",
	googlePlaceApiKey: "AIzaSyDu2e2-jV2AbQM0qfjlCKylbw0oblhqPHc",
	maxOccurrences: 2000
};
