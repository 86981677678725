import { ManualSalesSettings } from "../../sales/manual-sales/manual-sales-settings.interface";
import {LoginResponse} from "../models/login-response";
// import {SellSettings} from "../../sell-tickets/sell-tickets.component";

export class LocalStorageRepository {
    private static CUSTOMER_TOKEN_KEY = "public_customer_token";    
    private static TOKEN_KEY = "token";    
    private static USER_KEY = "user";    
    private static SELL_SETTINGS_KEY = "sell-settings";
    private static USER_UUID = "user_uuid"; 


    public static removeCustomerToken() {       
        localStorage.removeItem(this.CUSTOMER_TOKEN_KEY);   
    }
                                                    
    public static setPublicCustomerToken(token: string) {
        localStorage.setItem(this.CUSTOMER_TOKEN_KEY, token);   
    }

    public static getPublicCustomerToken(): string {
        return localStorage.getItem(this.CUSTOMER_TOKEN_KEY);
    }

    public static setToken(token: string) {
        localStorage.setItem(this.TOKEN_KEY, token);               
    }                   

    public static getToken(): string {  
        return localStorage.getItem(this.TOKEN_KEY);
    }
 
    public static removeToken() {                       
        localStorage.removeItem(this.TOKEN_KEY);
    }

    public static setUser(user: LoginResponse) {
        localStorage.setItem(this.USER_KEY, JSON.stringify(user));     
    }

    public static getUser() {                 
        return JSON.parse(localStorage.getItem(this.USER_KEY));    
    }            

    public static removeUser() {                     
        localStorage.removeItem(this.USER_KEY);   
    }

    public static getLoginResponse(): LoginResponse {          
        return JSON.parse(localStorage.getItem(this.USER_KEY));     
    }

    public static getSellSettings(): ManualSalesSettings { // SellSettings {  
        return JSON.parse(localStorage.getItem(this.SELL_SETTINGS_KEY) || '{}');
    }

    public static setSellSettings(sellSettings: ManualSalesSettings) { // SellSettings) {
        localStorage.setItem(this.SELL_SETTINGS_KEY, JSON.stringify(sellSettings));
    }

    public static setUserUUID(uuid: string) {
        localStorage.setItem(this.USER_UUID, JSON.stringify(uuid));     
    }

    public static getUserUUID() {                 
        return JSON.parse(localStorage.getItem(this.USER_UUID));    
    }            

    public static removeUserUUID() {                     
        localStorage.removeItem(this.USER_UUID);   
    }

    public static asyncLocalStorage = {     
          setItem: function (key, value) {      
            return Promise.resolve().then(function () {       
                localStorage.setItem(key, value);     
            });
        },
           getItem: function (key) {       
            return Promise.resolve().then(function () {                              
                return localStorage.getItem(key);  
            });    
        },          
        removeItem: function (key) { 
            return Promise.resolve().then(function () {   
                return localStorage.removeItem(key);
            });
        }
    };

}
